import React from "react";
import { Comment } from "../../types";
import "./commentCard.css";
import { useNavigate } from "react-router-dom";

export default function CommentCard({ comment }: { comment: Comment }) {
  const navigate = useNavigate();

  return (
    <div className="comment">
      <div className="body">
        <p>{comment.body}</p>
      </div>
      <div className="username-holder">
        <span onClick={() => navigate("/profile/" + comment.user_id)}>
          {comment.username}
        </span>
      </div>
    </div>
  );
}
