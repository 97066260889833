import { NewPost, Post } from "../../types";

/**
 * creates a new post
 * @param post {Post} The post to be created
 * @returns {Promise<boolean>} true if successful, false otherwise
 */
export async function createNewPost(
  session: string,
  post: NewPost
): Promise<boolean> {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionId: session,
        title: post.title,
        body: post.body,
        link: post.link,
        file: "abcd",
      }),
    });
    return true;
  } catch (error) {
    console.error("Error Creating a new post", post, error);
    return false;
  }
}

/**
 * Pulls a list of all posts from the api
 * @returns {Promise<Post[]>} An array of posts
 */
export async function getPosts(session: string): Promise<Post[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${session}`
    );
    const { data } = await response.json();
    return data as Post[];
  } catch (error) {
    console.error("Error fetching posts", error);
    return [];
  }
}

export async function postComment(
  session: string,
  postid: number,
  body: string,
  link: string,
  file: string,
  hasCommented: boolean
) {
  try {
    let method = hasCommented ? "PUT" : "POST";
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${postid}/comment`,
      {
        method: method,
        body: JSON.stringify({
          sessionId: session,
          body: body,
          link: link,
          file: file,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    console.error("Error posting comment", error);
  }
}

/**
 * This function will dislike or like a post for the current post id based on the like boolean
 * @param post_id {number} The post id to like or dislike
 * @param like {boolean} true if you want to like the post, false if you want to dislike the post
 * @returns {Promise<boolean>} true if the post was liked or disliked, false if there was an error
 */
export async function likeOrDislikePost(
  session: string,
  post_id: number,
  like: boolean
): Promise<boolean> {
  let endpoint = `/posts/${post_id}/`;
  if (!like) {
    endpoint += "dislike";
  } else {
    endpoint += "like";
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
      {
        method: "POST",
        body: JSON.stringify({
          sessionId: session,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error liking/disliking post, refreshing anyways", error);
    return false;
  }
}

/**
 * This function will delete the post with the current post id
 * @param post_id {number} The post id to delete
 * @returns {Promise<boolean>} true if the post was deleted, false if there was an error
 */
export async function deletePost(
  session: string,
  post_id: number
): Promise<boolean> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${post_id}`,
      {
        method: "DELETE",
        body: JSON.stringify({
          sessionId: session,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error deleting post, refreshing anyways", error);
    return false;
  }
}

/**
 * This function will pull the post with the current post id
 * @param post_id {number} The post id to pull
 * @returns {Promise<Post>} The post with the current post id
 */
export async function postWithSpecificId(
  post_id: number,
  session: string
): Promise<Post | undefined> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/posts/${post_id}/${session}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const { data } = await response.json();
    return data as Post;
  } catch (error) {
    console.error("Error fetching post with id", post_id, error);
  }
}
