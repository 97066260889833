import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CreatePost } from "./components/createPost";
import { ErrorPage } from "./components/errorPage";
import { Header } from "./components/header";
import { Feed } from "./components/feed";
import Profile from "./components/profile";
import { RecoilRoot } from "recoil";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const RouterErrorPage = () => (
  <>
    <Header />
    <ErrorPage />
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Feed />,
      </>
    ),
    errorElement: <RouterErrorPage />,
  },
  {
    path: "/create-post",
    element: (
      <>
        <Header />
        <CreatePost />
      </>
    ),
    errorElement: <RouterErrorPage />,
  },
  {
    path: "/error",
    element: (
      <>
        <Header />
        <ErrorPage />
      </>
    ),
    errorElement: <RouterErrorPage />,
  },
  {
    path: "/profile/:id",
    action: ({ params }) => {},
    element: (
      <>
        <Header />
        <Profile />
      </>
    ),
    errorElement: <RouterErrorPage />,
  },
]);

// const App = () => {
//   const { sessionId } = useRecoilValue(userState);

//   if (!sessionId) {
//     return (
//       <>
//         <Header login={true} />
//         <Login />
//       </>
//     );
//   }
//   } else if (path === "/create-post") {
//     return (
//       <>
//         <Header
//           userId={userID!}
//           setPath={setPath}
//           setSession={setSession}
//           login={false}
//         />
//         <CreatePost session={session} setPath={setPath} />
//       </>
//     );
//   } else if (path === "/error") {
//     return (
//       <>
//         <Header
//           userId={userID!}
//           setPath={setPath}
//           setSession={setSession}
//           login={false}
//         />
//         <ErrorPage />
//       </>
//     );
//   } else if (path.slice(0, 8) === "/profile") {
//     let profileId = path.slice(9);
//     return (
//       <>
//         <Header
//           userId={userID!}
//           setPath={setPath}
//           setSession={setSession}
//           login={false}
//         />
//         <Profile setPath={setPath} session={session} profileId={profileId} />
//       </>
//     );
//   } else {
//     // if path is something it shouldn't be just defer to feed
//     return (
//       <>
//         <Header
//           userId={userID!}
//           setPath={setPath}
//           setSession={setSession}
//           login={false}
//         />
//         <Feed setPath={setPath} setSession={setSession} session={session} />
//       </>
//     );
//   }
// };

const clientId = process.env.REACT_APP_CLIENT_ID;

if (!clientId) {
  throw new Error(" CLIENT_ID specified");
}
console.log("clientId", clientId);

const domNode = document.getElementById("root");
const root = createRoot(domNode!);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <GoogleOAuthProvider clientId={clientId}>
        <RouterProvider router={router} fallbackElement={<ErrorPage />} />
      </GoogleOAuthProvider>
    </RecoilRoot>
  </React.StrictMode>
);
