import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "loggedIn",
  storage: localStorage,
});

export interface User {
  userId: string;
  sessionId: string;
}

export const emptyUserRecoilState: User = {
  userId: "",
  sessionId: "",
};

export const userState = atom<User>({
  key: "userState", // unique ID (with respect to other atoms/selectors)
  default: emptyUserRecoilState, // default value (aka initial value)
  effects: [persistAtom],
});
