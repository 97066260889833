import React from "react";
import { postComment } from "../data";
import "./commentBox.css";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms";

export default function CommentBox({
  postId,
  refreshPosts,
  hasCommented,
}: {
  postId: number;
  refreshPosts: () => void;
  hasCommented: boolean;
}) {
  const { sessionId } = useRecoilValue(userState);

  //text
  const [newComment, setNewComment] = React.useState("");

  const addComment = async () => {
    await postComment(sessionId, postId, newComment, "", "", hasCommented);
    await refreshPosts();
  };

  return (
    <div className="comment-box">
      <input
        type="text"
        onChange={(e) => setNewComment(e.target.value)}
        defaultValue={"Make/edit comment"}
        className="text-box"
      />
      <br />

      <button onClick={addComment}>Post</button>
    </div>
  );
}
