import React from "react";
import CommentCard from "../commentCard";
import { Comment } from "../../types";
import CommentBox from "../commentBox";
import "./comments.css";
import { useNavigate } from "react-router-dom";

export default function Comments({
  postId,
  hasCommented,
  comments,
  refreshPosts,
}: {
  postId: number;
  hasCommented: boolean;
  comments: Comment[];
  refreshPosts: () => void;
}) {
  return (
    <div className="container">
      <h4 className="add-comment">Comments</h4>
      {comments.map((comment, index) => (
        <CommentCard key={index} comment={comment} />
      ))}
      <CommentBox
        hasCommented={hasCommented}
        postId={postId}
        refreshPosts={refreshPosts}
      />
    </div>
  );
}
