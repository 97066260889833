import React from "react";

export default function EditableProfileAttribute(props: {
  refreshProfile: () => void;
  session: string;
  name: string;
  value: string;
}) {
  // attributeState, setAttributeState = useState(display)
  const [editState, setEditState] = React.useState<boolean>(false); // this state is true when the component is being edited
  const [newAttribute, setNewAttribute] = React.useState<string>(props.value);

  function editAttribute() {
    setEditState(true);
  }

  async function sendEdit() {
    // make the appropriate fetch request based on the name the session and the newAttribute
    let endpoint;
    switch (props.name) {
      case "Sexual Orientation":
        endpoint = "orientation";
        break;
      case "Gender Identity":
        endpoint = "identity";
        break;
      case "Note":
        endpoint = "note";
    }

    let url = `${process.env.REACT_APP_BACKEND_URL}/profile/${endpoint}`;
    let body = {
      sessionId: props.session,
      newAttribute: newAttribute,
    };
    console.log(newAttribute);
    let valid = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (valid) {
      props.refreshProfile();
      setEditState(false);
    }
  }

  if (editState === false) {
    return (
      <li>
        {props.name}: <span>{props.value}</span>{" "}
        <button onClick={editAttribute}>Edit</button>
      </li>
    );
  } else {
    if (props.name === "Sexual Orientation") {
      return (
        <li>
          {props.name}:
          <select
            value={newAttribute}
            onChange={(e) => setNewAttribute(e.target.value)}
          >
            <option value="Straight or heterosexual">
              Straight or heterosexual
            </option>
            <option value="Lesbian or gay">Lesbian or gay</option>
            <option value="Bisexual">Bisexual</option>
            <option value="Queer, pansexual, and/or questioning">
              Queer, pansexual, and/or questioning
            </option>
            <option value="Something else">Something else</option>
            <option value="Don't know">Don't know</option>
            <option value="Decline to answer">Decline to answer</option>
          </select>
          <button onClick={sendEdit}>Submit</button>
        </li>
      );
    } else if (props.name === "Gender Identity") {
      return (
        <li>
          {props.name}:
          <select
            value={newAttribute}
            onChange={(e) => setNewAttribute(e.target.value)}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Bisexual">Bisexual</option>
            <option value="Transgender man/trans man">
              Transgender man/trans man
            </option>
            <option value="Transgender woman/trans woman">
              Transgender woman/trans woman
            </option>
            <option value="Genderqueer/gender nonconforming neither exclusively male nor female">
              Genderqueer/gender nonconforming neither exclusively male nor
              female
            </option>
            <option value="Additional gender category">
              Additional gender category
            </option>
            <option value="Decline to answer">Decline to answer</option>
          </select>
          <button onClick={sendEdit}>Submit</button>
        </li>
      );
    }
    return (
      <li>
        {props.name}:{" "}
        <input
          type="text"
          defaultValue={props.value}
          onChange={(e) => setNewAttribute(e.target.value)}
        />
        <button onClick={sendEdit}>Submit</button>
      </li>
    );
  }
}
