import React, { useCallback, useEffect } from "react";
import { Post } from "../../types";
import { getPosts } from "../data";
import { PostCard } from "../postCard";
import { userState } from "../atoms";
import { useRecoilValue } from "recoil";
import { isEmpty } from "lodash";

/**
 * @description This component will render a list of posts
 * @component Feed
 */
export function Feed() {
  const [posts, setPosts] = React.useState<Post[]>([]);
  const { sessionId } = useRecoilValue(userState);

  const refreshPosts = useCallback(async () => {
    if (!sessionId) {
      return;
    }
    let posts = await getPosts(sessionId);
    setPosts(posts);
  }, [sessionId]);

  useEffect(() => {
    async function fetchData() {
      await refreshPosts();
    }
    fetchData();
  }, [refreshPosts]);
  return (
    <div className="container">
      {!isEmpty(sessionId) ? (
        <>
          <h3>Feed</h3>
          {posts.length === 0 && <p>No posts yet</p>}
          {posts.map((post, index) => (
            <PostCard key={index} post={post} refreshPosts={refreshPosts} />
          ))}
        </>
      ) : (
        <p> You must be logged in to view the feed </p>
      )}
    </div>
  );
}
