import React from "react";
import { Post } from "../../types";
import { likeOrDislikePost } from "../data";
import { ThumbsUp } from "../icons/thumbsup";
import { ThumbsDown } from "../icons/thumbsdown";
import Comments from "../comments";
import "./postCard.css";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms";
import { useNavigate } from "react-router-dom";

export function PostCard({
  post,
  refreshPosts,
}: {
  post: Post;
  refreshPosts: () => void;
}) {
  const navigate = useNavigate();
  const { sessionId } = useRecoilValue(userState);
  const handleLike = async () => {
    const valid = await likeOrDislikePost(sessionId, post.post_id, true);
    if (valid) {
      await refreshPosts();
    }
  };

  const handleDislike = async () => {
    const valid = await likeOrDislikePost(sessionId, post.post_id, false);
    if (valid) {
      await refreshPosts();
    }
  };

  return (
    <div className="card">
      <div className="title-holder">
        <h2 className="title">{post.title}</h2>
      </div>
      <div className="post-card-body">
        <p>{post.body}</p>
      </div>
      <div className="post-card-link">
        <a href={post.link}>{post.link}</a>
      </div>

      <div className="post-card-activity">
        <div className="post-card-activity-section">
          <div onClick={async (e) => await handleLike()}>
            <ThumbsUp filled={post.hasLiked} />
          </div>
          <p>{JSON.stringify(post.activity.likes)}</p>
          <div onClick={async (e) => await handleDislike()}>
            <ThumbsDown filled={post.hasDisliked} />
          </div>
          <p>{post.activity.dislikes}</p>
        </div>
        <p>
          <a href={"/profile/" + post.user_id}>{post.username} </a>
        </p>
      </div>
      <Comments
        postId={post.post_id}
        hasCommented={post.hasCommented}
        comments={post.activity.comments}
        refreshPosts={refreshPosts}
      />
    </div>
  );
}
