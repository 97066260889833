import React, { useCallback, useEffect } from "react";
import { UserProfile } from "../../types";
import EditableProfileAttribute from "../editableProfileAttribute";
import "./profile.css";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms";
import { useParams } from "react-router-dom";

export default function Profile() {
  let baseProfile = {
    userName: "",
    email: "",
    orien: "",
    iden: "",
    note: "",
  };
  const params = useParams();
  const { sessionId } = useRecoilValue(userState);

  const [profile, setProfile] = React.useState<UserProfile>(baseProfile);

  const refreshProfile = useCallback(async () => {
    let fetchOutput = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/profile/${params.id}/${sessionId}`
    );
    let data = await fetchOutput.json();
    console.log(data.data);
    setProfile(data.data);
  }, [params.id, sessionId]);

  useEffect(() => {
    refreshProfile();
  }, [refreshProfile]);

  if (profile.iden) {
    // then we know we're the user
    return (
      <div className="profilePage">
        <div className="username">{profile.userName}</div>
        <div className="profileInfo">
          <ul>
            <EditableProfileAttribute
              session={sessionId}
              refreshProfile={refreshProfile}
              name="Sexual Orientation"
              value={profile.orien!}
            />
            <EditableProfileAttribute
              session={sessionId}
              refreshProfile={refreshProfile}
              name="Gender Identity"
              value={profile.iden!}
            />
            <li>Email: {profile.email}</li>
            <EditableProfileAttribute
              session={sessionId}
              refreshProfile={refreshProfile}
              name="Note"
              value={profile.note!}
            />
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div className="profilePage">
        <div className="username">{profile.userName}</div>
        <div className="profileInfo">
          <ul>
            <li>Email: {profile.email}</li>
            <li>Note: {profile.note}</li>
          </ul>
        </div>
      </div>
    );
  }
}
