import React from "react";
import { useNavigate } from "react-router-dom";
import { NewPost } from "../../types";
import { createNewPost } from "../data";
import "./createNewPost.css";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms";

/**
 * @description This component will render a form to create a new post and submit it to the api
 * @param setPath {function} This function will let this component change the path
 * @component CreateNewPost
 */
export function CreatePost() {
  const { sessionId } = useRecoilValue(userState);
  const navigate = useNavigate();

  const [title, setTitle] = React.useState("");
  const [body, setBody] = React.useState("");
  const [link, setLink] = React.useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPost: NewPost = {
      title,
      body,
      link,
      file: "",
    };
    await createNewPost(sessionId, newPost);
    navigate("/");
  };

  return (
    <div className="container">
      <h3>Create Post</h3>
      <div className="card">
        <form className="stack" onSubmit={handleSubmit}>
          <label>Title goes here:</label>
          <input
            type="text"
            id="title"
            onChange={(e) => setTitle(e.target.value)}
          />
          <label>Body goes here:</label>
          <textarea id="body" onChange={(e) => setBody(e.target.value)} />
          <label>Link goes here:</label>
          <textarea id="body" onChange={(e) => setLink(e.target.value)} />

          <div className="create-card-footer">
            <button className="button" style={{ width: "100px" }}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
