import React from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { emptyUserRecoilState, userState } from "../atoms";
import { GoogleLogin } from "@react-oauth/google";
import { isEmpty } from "lodash";

/**
 * @description This component will render the header
 * @param setPath {function} This function will allow the component to change the path
 * @component Header
 */
export function Header() {
  const [user, setUser] = useRecoilState(userState);
  const navigate = useNavigate();

  const onSuccess = async (res: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: res.credential }),
        }
      );
      const jsonRes = await response.json();
      const resObj = jsonRes.data;
      setUser(resObj);
    } catch (err) {
      onFailure();
    }
  };

  const onFailure = () => {
    console.log("Login failed");
    navigate("/error");
  };

  return (
    <div className="header-container">
      <h1>The Buzz</h1>
      {isEmpty(user.sessionId) ? (
        <div>
          <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
        </div>
      ) : (
        <div>
          <button className="button" onClick={(e) => navigate("/")}>
            Feed
          </button>
          <button className="button" onClick={(e) => navigate("/create-post")}>
            Create Post
          </button>
          <button
            className="button"
            onClick={(e) => navigate("/profile/" + user.userId)}
          >
            My Profile
          </button>
          <button
            className="button"
            onClick={(e) => setUser(emptyUserRecoilState)}
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}
